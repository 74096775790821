body {
  padding: 0;
  margin: 0;
}

.cmc-wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-height: 100dvh;
}

.cmc-aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 300px;
  height: 100dvh;
  overflow-y: auto;
  color: #FFFFFF;
  background: #0C3C6B;
}

.cmc-aside a {
  color: #DFDFDF;
  text-decoration: none;
}

.cmc-aside ul {
  padding: 1rem 0 1rem 1rem;
  margin: 0;
  list-style: none;
}

.cmc-aside .cmc-nav-ul ul {
  padding: 0.25rem 0 1rem 0.5rem;
  margin: 0;
  list-style: none;
}

.cmc-aside a:hover {
  text-decoration: underline;
}

.cmc-main {
  flex: 1;
  padding: 1rem;
  margin-left: 300px;
  overflow-x: hidden;
}

.react-live-preview {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #657B83;
}

.dashboard-content-header {
  background-color: #00FF00;
}

.dashboard-content-body {
  color: white;
  background-color: #0000FF;
}

.dashboard-content-footer {
  background-color: #FFFF00;
}
